import { ITreeNode } from '@/components/tree/tree-view';
import { useSidebar } from '@neanic/io-vue';
import NewMessageComponent from '@/pages/profile/message/new.vue';
import { hasServiceType, hasWorkspace, isRoot } from '@/providers/utils';
import { NeanicClient } from '@neanic/io';

export interface INavigationNode extends ITreeNode<INavigationNode>{
    icon?: string,
    onClick?: (() => Promise<void>) | string;
    isFaded?: boolean;
    isHidden?: boolean;
}

export function getNav(neanic: NeanicClient, parentName?: string)
{
    const items: INavigationNode[] = [{
        title: neanic.session?.fullName
    },{
        title: 'Profile',
        icon: 'fa-user',
        onClick: 'profile',
    },{
        title: 'Messages',
        icon: 'fa-message',
        onClick: 'message',
        // children: [{
        //     title: 'New Message',
        //     class: 'btn btn-primary ',
        //     icon: 'fa-plus',
        //     onClick: async () => {
        //         const feedback = await useSidebar().open('New Message', NewMessageComponent);
        //     }
        // },{
        //     title: 'Inbox',
        //     icon: 'fa-inbox',
        //     onClick: 'inbox'
        // },{
        //     title: 'Outbox',
        //     icon: 'fa-reply',
        //     onClick: 'outbox'
        // }]
    },{
        title: neanic.session?.workspaceName,
        isHidden: !hasWorkspace(neanic)
    },{
        title: 'Dashboard',
        icon: 'fa-server',
        onClick: 'workspace-dashboard',
        isHidden: !hasWorkspace(neanic)
    },{
        title: 'Members',
        icon: 'fa-users',
        onClick: 'members',
        isHidden: !hasWorkspace(neanic)
    },{
        title: 'Purchases',
        icon: 'fa-timeline',
        onClick: 'purchases',
        isHidden: !hasWorkspace(neanic)
    },{
        title: 'Partner Management',
        icon: 'fa-handshake',
        isHidden: !hasServiceType('partner'),
        onClick: 'partner',
        children: [{
            title: 'Affiliates',
            icon: 'fa-people-roof',
            isHidden: !hasServiceType('partner'),
            onClick: 'affiliates',
        },{
            title: 'Products',
            icon: 'fa-wallet',
            isHidden: !hasServiceType('partner'),
            onClick: 'products'
        },{
            title: 'Coupons',
            icon: 'fa-ticket',
            isHidden: !hasServiceType('partner'),
            onClick: 'coupons'
        },{
            title: 'Orders',
            icon: 'fa-timeline',
            isHidden: !hasServiceType('partner'),
            onClick: 'orders'
        }]
    },{
        title: 'API Services',
        icon: 'fa-satellite-dish',
        onClick: 'api',
        isHidden: !hasServiceType('api'),
        children: [{
            title: 'Get started',
            icon: 'fa-circle-info',
            isHidden: !hasServiceType('api'),
            onClick: 'api-get-started'
        },{
            title: 'Access Keys',
            icon: 'fa-key',
            isHidden: !hasServiceType('api'),
            onClick: 'access-keys'
        }, {
            title: 'Requests',
            icon: 'fa-right-left',
            isHidden: !hasServiceType('api'),
            onClick: 'requests'
        }]
    },{
        title: 'Widget Access',
        icon: 'fa-puzzle-piece',
        onClick: 'widget',
        isHidden: !hasServiceType('widget'),
        children: [{
            title: 'Get started',
            icon: 'fa-circle-info',
            isHidden: !hasServiceType('widget'),
            onClick: 'widget-get-started'
        },{
            title: 'URL Filters',
            icon: 'fa-at',
            isHidden: !hasServiceType('widget'),
            onClick: 'url-filters'
        },{
            title: 'Listings',
            icon: 'fa-list-ul',
            isHidden: !hasServiceType('widget'),
            onClick: 'listings'
        },{
            title: 'Leads',
            icon: 'fa-people-group',
            isHidden: !hasServiceType('widget'),
            onClick: 'leads'
        }]
    },{
        title: 'System',
        isHidden: !isRoot(neanic),
        children: [{
            title: 'Accounts',
            icon: 'fa-users',
            onClick: 'accounts'
        },{
            title: 'Workspaces',
            icon: 'fa-sitemap',
            onClick: 'workspaces'
        },{
            title: 'Services',
            icon: 'fa-list-check',
            onClick: 'system-services'
        },{
            title: 'Products',
            icon: 'fa-list-check',
            onClick: 'products'
        },{
            title: 'Coupons',
            icon: 'fa-ticket',
            onClick: 'coupons'
        },{
            title: 'Transactions',
            icon: 'fa-credit-card',
            onClick: 'system-transactions'
        }]
    }];

    let result = items;
    if (typeof parentName === 'string'){
        const item = items.find(x => 
            typeof x.onClick === 'string' && 
            x.onClick === parentName);

        result = item?.children || [];
    }

    return result.filter(x => !x.isHidden).map(item => {
        return item.isFaded ? { ...item, children: []} : item;
    });
}